import React from "react"
import {Link} from "gatsby"
import * as PropTypes from "prop-types"
import * as styles from "./CheckoutStepper.module.scss"

const CheckoutStepper = ({children,activeStepNumber}) => {

  const totalCount = React.Children.count(children)
  if(totalCount === 0){
    return <div/>
  }

  const content = React.Children.map(children, (element, index) => {
    const {pageLink,title} = element.props
    const currentNumber = index + 1

    let borderClass = ""

    if(currentNumber<activeStepNumber){
      borderClass = styles.completed
    } else if(currentNumber === activeStepNumber){
      borderClass = styles.active
    }

    return (
      <div key={index} className={`${styles.step} ${borderClass}`}>
        {currentNumber>=activeStepNumber ? (
          <span>{title}</span>
        ) : (
          <Link to={pageLink} className="text-decoration-none">{title}</Link>
        )}
        <p className={styles.currentStepLine}>{`${activeStepNumber}/${totalCount}`}</p>
        <div className={styles.desktopLine}>
          <div className={styles.leftLine}/>
          <div className={styles.rightLine}/>
        </div>
        <div className={styles.mobileLine}>
          {Array(totalCount).fill(1,0).map((_, i) => {
            const currentNumber = i+1;
            return (
              <div key={currentNumber} className={currentNumber <= activeStepNumber ? styles.mobileLineCompleted: styles.mobileLineActive}/>
            )
          })}
        </div>
      </div>
    )})

    return (
      <div className={styles.stepper}>
        {content}
      </div>
    )
}

CheckoutStepper.propTypes = {
  activeStepNumber: PropTypes.number.isRequired
}

export default CheckoutStepper
