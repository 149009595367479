import React from "react"
import { Fragment } from "react"
import { Button } from "react-bootstrap"
import { basketActions } from "../../state/basket"
import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"
import * as style from "./IDDFincanceModal.module.scss"
import { product } from "../../utils/checkoutHelper";
import {navigate} from "gatsby";
import {financeOperations} from "../../state/finance";

const IDDFinanceModal = ({
                           finalizeProcess,
                           removeAdditionalServices,
                           hideAdditionalServicesPopup,
                           additionalServices,
                           addItemToBasket,
                           addAdditionalServices
                         }) => {
  const handleClick = name => {
    if(name === "accept") {
      finalizeProcess()
      hideAdditionalServicesPopup()
    } else {
      hideAdditionalServicesPopup()
    }
  }

  return (
      <Fragment>
        <div className={style.assemblyContainer}>
          <div className={`container-fluid px-0 position-relative`}>
            <div className={` h-auto`}>
              <div className={style.marketingBg}/>
              <div
                  className={`text-white px-lg-5 px-3 pt-4 position-absolute ${style.top} w-100`}>
                <div className="mt-lg-5 mt-xl-4">
                  <p className="h3 pb-3 text-uppercase text-white w-75">
                    {" "}
                    Central Bikes Limited
                    Initial Disclosure agreement
                  </p>
                  <p>
                    Central Bikes Limted are authorised and regulated by the Financial Conduct Authorised under RFN 659999.
                  </p>
                  <p>
                    Central Bikes Limted is authorised as a credit broker, not a lender.
                  </p>
                  <p>
                    We are committed to treating customers fairly whilst ensuring our products and services are suitable for their needs.
                  </p>
                  <p>
                    We can introduce you to a limited number of lenders who might be able to finance your purchase. We will only introduce you to these lenders.
                  </p>
                  <p>
                    You may be able to obtain finance for the purchase from other lenders and we encourage you to seek alternative quotations.
                  </p>
                  <p>
                    We, Central Bikes Limted, may receive a commission payment for introducing you to a lender. This could be a fixed amount or a fixed % of the lend. You may request the disclosure of this amount.
                  </p>
                  <p>
                    Please note, if you have any health issues, difficulty understanding information or there are any recent life
                    events that could affect your ability to fully understand the information and documentation presented, you
                    should carefully consider the amount of time you require to review the documentation. You should also
                    consider if it is advisable for you to have someone you know, help you make your decision. You should asses
                    the monthly payments you are required to make throughout the agreement and ensure you are able to meet
                    these obligations.
                  </p>
                  <p>
                    Your credit rating could be adversely affected if you do not keep up with your payments. This could make it
                    harder for you to obtain credit in the future and/or make it more expensive.
                  </p>
                  <p>
                    If you wish to register a complaint, please contact us in writing at info@centralbikes.co.uk or by post at Central
                    Bikes, 15 Shepherds Bush Road, London, W6 7LX. You can also contact us via phone on 020 3319 2800.
                  </p>
                  <p>
                    If you would like to know how we handle complaints, please ask for a copy of our complaints handling process.
                    If you cannot resolve a complaint with us, you may be entitled to refer it to the Financial Ombudsman Service,
                    In writing: The Financial Ombudsman Service, Exchange Tower, London, E14 9SR.
                  </p>
                  <p>
                    Throughout the process of administering services for you, we will need to collect personal information from
                    you and pass this information to one or more third party organisations in order for them to supply any service
                    you request. This may include passing this information to one or more potential lenders or credit brokers to
                    enable them to make a credit decision. These lenders may use your information to conduct an affordability and
                    credit worthiness assessment and will do so using the consent given by you.
                  </p>
                  <p>
                    By clicking I agree button, I acknowledge that I have read, understood and accept the terms stipulated above.
                  </p>
                  <div className="d-flex pt-4 align-items-center">
                    <Button
                        onClick={e => handleClick(e.target.name)}
                        name="accept"
                        className={`${style.offerBtn} px-3 px-md-4 border-0`}>
                      I agree
                    </Button>
                    <Button
                        onClick={e => handleClick(e.target.name)}
                        name="ignore"
                        className={`${style.offerBtn} bg-danger border-0 px-3 px-md-4 ml-3`}>
                      I disagree
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
  )
}

const mapStateToProps = createStructuredSelector({

})

const mapDispatchToProps = {
  finalizeProcess: financeOperations.finalizeProcess,
  addItemToBasket: basketActions.addItemToBasket,
  addAdditionalServices: basketActions.addAdditionalServices,
  removeAdditionalServices: basketActions.removeAdditionalServices,
  hideAdditionalServicesPopup: basketActions.hideAdditionalServices,
}
export default connect(mapStateToProps, mapDispatchToProps)(IDDFinanceModal)
