// extracted by mini-css-extract-plugin
export var active = "CheckoutStepper-module--active--e6c55";
export var bgGrey100 = "CheckoutStepper-module--bg-grey-100--0a2ee";
export var bgGrey150 = "CheckoutStepper-module--bg-grey-150--2ae7b";
export var bgGrey200 = "CheckoutStepper-module--bg-grey-200--a26e5";
export var bgGrey300 = "CheckoutStepper-module--bg-grey-300--38947";
export var bgGrey400 = "CheckoutStepper-module--bg-grey-400--53878";
export var bgGrey500 = "CheckoutStepper-module--bg-grey-500--f7e60";
export var bgGrey600 = "CheckoutStepper-module--bg-grey-600--9bb90";
export var bgGrey700 = "CheckoutStepper-module--bg-grey-700--46044";
export var bgGrey800 = "CheckoutStepper-module--bg-grey-800--cb48f";
export var bgGrey900 = "CheckoutStepper-module--bg-grey-900--1bbea";
export var completed = "CheckoutStepper-module--completed--03546";
export var currentStepLine = "CheckoutStepper-module--currentStepLine--9cca7";
export var desktopLine = "CheckoutStepper-module--desktopLine--6ebcc";
export var leftLine = "CheckoutStepper-module--left-line--13bfd";
export var mobileLine = "CheckoutStepper-module--mobileLine--7a550";
export var mobileLineActive = "CheckoutStepper-module--mobile-line-active--497ab";
export var mobileLineCompleted = "CheckoutStepper-module--mobile-line-completed--3a4e6";
export var rightLine = "CheckoutStepper-module--right-line--3ecff";
export var step = "CheckoutStepper-module--step--66d2d";
export var stepper = "CheckoutStepper-module--stepper--a93c0";
export var textGrey100 = "CheckoutStepper-module--text-grey-100--37946";
export var textGrey150 = "CheckoutStepper-module--text-grey-150--4104b";
export var textGrey200 = "CheckoutStepper-module--text-grey-200--e2813";
export var textGrey300 = "CheckoutStepper-module--text-grey-300--05c52";
export var textGrey400 = "CheckoutStepper-module--text-grey-400--af9e1";
export var textGrey500 = "CheckoutStepper-module--text-grey-500--79f80";
export var textGrey600 = "CheckoutStepper-module--text-grey-600--4aebf";
export var textGrey700 = "CheckoutStepper-module--text-grey-700--02741";
export var textGrey800 = "CheckoutStepper-module--text-grey-800--1febb";
export var textGrey900 = "CheckoutStepper-module--text-grey-900--3de5f";