import React, {useEffect} from "react"
import {connect} from "react-redux"
import {Container, Row, Col, Button, Form} from "react-bootstrap"
import Layout from "../components/Layout"
import CheckoutStepper, { CheckoutStep } from "../components/CheckoutStepper"
import { financeSelectors, financeOperations, financeActions } from "../state/finance"
import { basketSelectors, basketActions } from "../state/basket"
import ProductSelectedAccessories from "../components/ProductSelectedAccessories"
import * as styles from "./finance-checkout-summary.module.scss"
import {dataLayerUpdate} from "../../src/api/dataLayer/gaDataLayer";
import IDDFinanceModal from "../templates/ProductUI/IDDFinanceModal";
import {ModalPortal} from "../portals/Modal.portal";

const CheckoutFinanceSummaryPage = ({ location, product,quote,accessories,
                                      finalizeProcess,isProcessing, isError,
                                      isSuccess,finishFinancingProcess,getFinalFinancing,
                                      quoteWithAccessories,queryTermInMonths,getPersonalDetails,
                                      isShippingSelected, setUserEmail, setUserPhoneNumber,
                                      hideAdditionalServices, showAdditionalServices, isAdditionalServicesVisible,
}) => {
  dataLayerUpdate('finance_summary', product)
  const { Figures, QuoteUID } = quote || {}
  const { TermQuoted, RegularPayment } = Figures || {}
  const { extraFee } = product?.beforeAddToBasketNotification || {}
  const { email, phoneNumber, deliveryMethod }= getPersonalDetails || {}
  const productImage = product?.featuredImage.node.sourceUrl
  const url = location.href
  const handleApply = () => {
    showAdditionalServices()
  }

  useEffect(() => {
    setUserPhoneNumber(phoneNumber)
    setUserEmail(email)
    getFinalFinancing(isShippingSelected)
  },[])

  useEffect(()=>{
    if(isSuccess){
      finishFinancingProcess()

      if(quoteWithAccessories != null){
        window.location = `https://my.newvehicle.com/start?quoteId=${quoteWithAccessories.QuoteUID}&imageUrl=${productImage}?width=350&returnUrl=${url}&redirectTo=apply`
      } else {
        window.location = `https://my.newvehicle.com/start?quoteId=${QuoteUID}&imageUrl=${productImage}?width=350&returnUrl=${url}&redirectTo=apply`
      }
    }
  },[isSuccess])

  return (
    <Layout
      staticSeo={{ seoTitle: "Checkout finance", seoDescription: "Checkout finance process" }}
      location={{ pathname: location.pathname }}
    >
      {isAdditionalServicesVisible && (
          <ModalPortal
              onHide={() => hideAdditionalServices(false)}
              size="lg"
              centered={true}>
            <IDDFinanceModal />
          </ModalPortal>
      )}
      <CheckoutStepper activeStepNumber={4}>
        <CheckoutStep pageLink={"/finance-checkout-product"} title="Choose a bike" />
        <CheckoutStep pageLink={"/finance-checkout-accessories"} title="Choose accessories" />
        <CheckoutStep pageLink={"/finance-checkout-data"} title="Choose delivery method" />
        <CheckoutStep pageLink={location.pathname} title="Finish" />
      </CheckoutStepper>
      <Container className="my-5">
        <Row>
          <Col xs={12}>
            <h3 className="pb-4">Your application is almost ready</h3>
            <p className="m-0">
              When you confirm all details then you will be redirected to the iVendi site to fill in application form after you click <i>Accept</i> button
              Our sales team will contact you within 48h
            </p>
            <hr className="my-3 my-lg-5" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={5} lg={4}>
            <div className="d-flex flex-column align-items-sm-stretch">
              <p><strong>Choosen bike</strong><br/>{product?.name}</p>
              <p><strong>Price</strong><br/>{product?.price}</p>
              <p><strong>Selected delivery method</strong><br/>{deliveryMethod === 'homeDelivery' ? 'Home delivery': 'Click & collect'}</p>
              { deliveryMethod === 'clickAndCollect'
                ? <>
                   <p><strong>Delivery Fee</strong><br/>Free</p>
                  </>
                : <>
                   <p><strong>Delivery Fee</strong><br/>{process.env.CURRENCY_SYMBOL}{process.env.SHIPPING_FEE}</p>
                  </>

              }
              {extraFee?.feeTitle && <p><strong>{extraFee.feeTitle}</strong><br/>{process.env.CURRENCY_SYMBOL}{extraFee.feeValue}</p> }
              <p><strong>E-mail</strong><br/>{email}</p>
              <p><strong>Phone number</strong><br/>{phoneNumber}</p>
              <p><strong>Financing product with click and collect</strong><br/>
                {`Pay ${process.env.CURRENCY_SYMBOL}${RegularPayment} for ${TermQuoted} months`}
              </p>
              {quoteWithAccessories && (
                <p><strong>Financing product with accessories or home delivery </strong><br/>
                  {`Pay ${process.env.CURRENCY_SYMBOL}${quoteWithAccessories.Figures.RegularPayment} for ${quoteWithAccessories.Figures.TermQuoted} months`}
                </p>
              )}
              {accessories && !!accessories.length && quoteWithAccessories === null && (
                <p><strong>Financing product with accessories</strong><br/>
                  {`We cannot include accessories into your financial request but our support team will contact you`}
                </p>
              )}

            </div>
          </Col>
          <Col xs={12} md={7} lg={8}>
            <img  alt="Product image" src={product?.featuredImage.node.sourceUrl} className={styles.productImage} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p><strong>Selected accessories</strong></p>
            <ProductSelectedAccessories products={accessories} queryTermInMonths={queryTermInMonths} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} >
            <hr className="my-3 my-lg-5" />
          </Col>
          <Col xs={6}>
          </Col>
          <Col xs={12} sm={6}>
            <Button type="button" className="w-100" id="applyForFinance" disabled={isProcessing} onClick={handleApply} >Apply for financing</Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const mapStateToProps = state => ({
  product: financeSelectors.getProduct(state),
  quote: financeSelectors.getQuote(state),
  quoteWithAccessories: financeSelectors.getQuoteWithAccessories(state),
  accessories: financeSelectors.getAccessories(state),
  isProcessing: financeSelectors.isProcessing(state),
  isError: financeSelectors.isError(state),
  isSuccess: financeSelectors.isSuccess(state),
  queryTermInMonths: financeSelectors.getQueryTermInMonths(state),
  getPersonalDetails: basketSelectors.getDeliveryOptionDetails(state),
  isShippingSelected: basketSelectors.isShippingSelected(state),
  isAdditionalServicesVisible: basketSelectors.isAdditionalServicesVisible(state),
});

const mapDispatchToProps = {
  finalizeProcess: financeOperations.finalizeProcess,
  finishFinancingProcess: financeActions.finishFinancingProcess,
  getFinalFinancing: financeOperations.getFinalFinancing,
  setUserEmail: financeActions.setUserEmail,
  setUserPhoneNumber: financeActions.setUserPhoneNumber,
  hideAdditionalServices: basketActions.hideAdditionalServices,
  showAdditionalServices: basketActions.showAdditionalServices,
};

export default connect(mapStateToProps, mapDispatchToProps) (CheckoutFinanceSummaryPage)
