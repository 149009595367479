// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductSelectedAccessoryCard-module--bg-grey-100--72bb4";
export var bgGrey150 = "ProductSelectedAccessoryCard-module--bg-grey-150--0d750";
export var bgGrey200 = "ProductSelectedAccessoryCard-module--bg-grey-200--fd747";
export var bgGrey300 = "ProductSelectedAccessoryCard-module--bg-grey-300--ee230";
export var bgGrey400 = "ProductSelectedAccessoryCard-module--bg-grey-400--eb04c";
export var bgGrey500 = "ProductSelectedAccessoryCard-module--bg-grey-500--4e34d";
export var bgGrey600 = "ProductSelectedAccessoryCard-module--bg-grey-600--c9685";
export var bgGrey700 = "ProductSelectedAccessoryCard-module--bg-grey-700--bf5c4";
export var bgGrey800 = "ProductSelectedAccessoryCard-module--bg-grey-800--6b97f";
export var bgGrey900 = "ProductSelectedAccessoryCard-module--bg-grey-900--94348";
export var card = "ProductSelectedAccessoryCard-module--card--8a24d";
export var colColor = "ProductSelectedAccessoryCard-module--colColor--2da4b";
export var colHeader = "ProductSelectedAccessoryCard-module--colHeader--8c916";
export var colIncreased = "ProductSelectedAccessoryCard-module--colIncreased--7b357";
export var colName = "ProductSelectedAccessoryCard-module--colName--4be10";
export var colPrice = "ProductSelectedAccessoryCard-module--colPrice--7a40d";
export var colSize = "ProductSelectedAccessoryCard-module--colSize--8979f";
export var mobileCard = "ProductSelectedAccessoryCard-module--mobileCard--ecf96";
export var mobileColColor = "ProductSelectedAccessoryCard-module--mobileColColor--3b709";
export var mobileColHeader = "ProductSelectedAccessoryCard-module--mobileColHeader--9aa98";
export var mobileColIncreased = "ProductSelectedAccessoryCard-module--mobileColIncreased--3f669";
export var mobileColName = "ProductSelectedAccessoryCard-module--mobileColName--13ea7";
export var mobileColPrice = "ProductSelectedAccessoryCard-module--mobileColPrice--be3c8";
export var mobileColSize = "ProductSelectedAccessoryCard-module--mobileColSize--876b9";
export var textGrey100 = "ProductSelectedAccessoryCard-module--text-grey-100--09bbe";
export var textGrey150 = "ProductSelectedAccessoryCard-module--text-grey-150--6ade1";
export var textGrey200 = "ProductSelectedAccessoryCard-module--text-grey-200--661cf";
export var textGrey300 = "ProductSelectedAccessoryCard-module--text-grey-300--4e48a";
export var textGrey400 = "ProductSelectedAccessoryCard-module--text-grey-400--8acb8";
export var textGrey500 = "ProductSelectedAccessoryCard-module--text-grey-500--38251";
export var textGrey600 = "ProductSelectedAccessoryCard-module--text-grey-600--2e8d9";
export var textGrey700 = "ProductSelectedAccessoryCard-module--text-grey-700--7a790";
export var textGrey800 = "ProductSelectedAccessoryCard-module--text-grey-800--219b0";
export var textGrey900 = "ProductSelectedAccessoryCard-module--text-grey-900--efd61";
export var variantButton = "ProductSelectedAccessoryCard-module--variantButton--18004";
export var variantCard = "ProductSelectedAccessoryCard-module--variantCard--e8dc0";