import React, { useState } from "react"
import * as PropTypes from "prop-types"
import * as styles from "./ProductSelectedAccessoryCard.module.scss"
import {getMinFinancingAccessoryValue, getMaxFinancingAccessoryValue} from "../../utils/ivendi"

const ProductSelectedAccessoryCard = ({product,variant, className,queryTermInMonths}) => {

  const getVariantAttributeByName = ( variant, attributeName ) => {
    return variant.attributes.find(attribute => attribute.name === attributeName) || ''
  }

  const getProductAttributeByName = (product, attributeName) => {
    if(!product.attributes){
      return ''
    }

    return product.attributes.find(attribute => attribute.name === attributeName) || ''
  }

  const attributeColor = !!variant ? getVariantAttributeByName(variant,'Colour') : getProductAttributeByName(product,'Colour')
  const attributeSize = !!variant ? getVariantAttributeByName(variant,'Size') : getProductAttributeByName(product,'Size')

  const priceElement = !!variant ? (
    <span>{`${process.env.CURRENCY_SYMBOL}${variant.price}`}</span>
  ) : (
    <span>{`${process.env.CURRENCY_SYMBOL}${product.price}`}</span>
  )

  const minRequestValue = getMinFinancingAccessoryValue(!!variant ? variant.price : product.price,queryTermInMonths);
  const maxRequestValue = getMaxFinancingAccessoryValue(!!variant ? variant.price : product.price,queryTermInMonths);

  const increasedByText = `${process.env.CURRENCY_SYMBOL}${minRequestValue} - ${process.env.CURRENCY_SYMBOL}${maxRequestValue}`

  const productNameElement = <a target="_blank" href={`${process.env.SITE_ACCESSORIES_URL}/products/${product.id}`}>{product.name}</a>

  return (
    <>
      <div className={styles.card}>
        <img src={!!variant ? variant.imageSrc : product.imageSrc} alt={product.imageAlt} width={90} height={90} />
        <div className={styles.colName}>
          <p className={styles.colHeader}>Product</p>
          {productNameElement}
        </div>
        <div className={styles.colPrice}>
          <p className={styles.colHeader}>Full Price</p>
          {priceElement}
        </div>

        <div className={styles.colColor}>
          <p className={styles.colHeader}>Color</p>
          <span>{attributeColor.value}</span>
        </div>

        <div className={styles.colSize}>
          <p className={styles.colHeader}>Size</p>
          <span>{attributeSize.value}</span>
        </div>
        <div className={styles.colIncreased}>
          <p className={styles.colHeader}>Increased of the monthly payment by</p>
          <span>{increasedByText}</span>
        </div>
      </div>
      <div className={styles.mobileCard}>
        <div className="d-flex flex-row align-items-center">
          <img src={!!variant ? variant.imageSrc : product.imageSrc} alt={product.imageAlt} width={90} height={90} />
          <p className={styles.mobileColName}>
            <span className={styles.mobileColHeader}>Product</span>
            <br/>
            <br/>
            <span className="mt-2">{productNameElement}</span>
          </p>
        </div>
        <hr className="my-2 w-100"/>
        <div className="d-flex flex-row">
          <div className={styles.mobileColPrice}>
            <p className={styles.mobileColHeader}>Full Price</p>
            {priceElement}
          </div>
          <div className={styles.mobileColIncreased}>
            <p className={styles.mobileColHeader}>Increased of the monthly payment by</p>
            <span>{increasedByText}</span>
          </div>
        </div>
        <hr className="my-2 w-100"/>
        <div className="d-flex flex-row">
          <div className={styles.mobileColColor}>
            <p className={styles.mobileColHeader}>Color</p>
            {attributeColor.value}
          </div>
          <div className={styles.mobileColSize}>
            <p className={styles.mobileColHeader}>Size</p>
            <span>{attributeSize.value}</span>
          </div>
        </div>
      </div>
    </>
  )
}

ProductSelectedAccessoryCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    stockStatus: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    variants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      imageSrc: PropTypes.string,
      stockStatus: PropTypes.string.isRequired,
      attributes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }))
    }))
  }),
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  readyOnly: PropTypes.bool,
  queryTermInMonths: PropTypes.number.isRequired
}

ProductSelectedAccessoryCard.defaultProps = {
  className: "",
  readyOnly: false,
}

export default ProductSelectedAccessoryCard
