import React from "react"
import { Row, Form } from 'react-bootstrap';
import { Link } from 'gatsby'

export const product = {
  type: "SimpleProduct",
  id: "cHJvZHVjdDoyNzQ1",
  name: "Bike Assembly",
  activePrice: 100,
  price: `${process.env.CURRENCY_SYMBOL}100`,
  productCategories: {
    nodes: [
      {
        parentId: null,
        name: "Bike Accessories",
        slug: "bike-accessories",
        id: "dGVybToxNQ==",
        link: "https://centralebikes.co.uk/bike-accessories",
      },
    ],
  },
  description:
    "<p>Bike Assembly</p>\n",
  shortDescription:
    "<p>Get your bike assembled</p>\n",
  regularPrice: `${process.env.CURRENCY_SYMBOL}100`,
  salePrice: null,
  databaseId: 2745,
  dateOnSaleFrom: null,
  dateOnSaleTo: null,
  stockStatus: "IN_STOCK",
  stockQuantity: 2,
  sku: "BA1",
  slug: "bike-assembly",
  featuredImage: {
    node: {
      id: "cG9zdDoxMDUw",
      altText: "",
      sourceUrl:
        "https://wp.centralebikes.co.uk/wp-content/uploads/2021/02/AdobeStock_136086706-scaled.jpeg",
      localFile: {
        childImageSharp: {
          fixed: {
            base64:
              "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHajywNFQLwAf/EABkQAQEAAwEAAAAAAAAAAAAAAAIBABARIP/aAAgBAQABBQKrmV6QVwBckvj/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAACAQUBAAAAAAAAAAAAAAABEQADECAiYbH/2gAIAQEABj8C7B2yDfsdRmFajD//xAAcEAEBAAEFAQAAAAAAAAAAAAABESEAEDFBUWH/2gAIAQEAAT8hIOTtLoDgnp62mwBWhj6up1rJOZ46zzo4E2m3/9oADAMBAAIAAwAAABAzyDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAeEAEAAgICAwEAAAAAAAAAAAABESEAQVGREDGBwf/aAAgBAQABPxCzEFpFMjdwSFDn8+4IgiI+kcKY5AqLItI74xcbJWUVAbi8S2QQmiWNSzWJPOV29+P/2Q==",
            width: 246,
            height: 260,
            src: "/static/3f107f7955942632259607be27147149/830a5/rb21830-1.jpg",
            srcSet:
              "/static/3f107f7955942632259607be27147149/830a5/rb21830-1.jpg 1x,\n/static/3f107f7955942632259607be27147149/b422e/rb21830-1.jpg 1.5x,\n/static/3f107f7955942632259607be27147149/78433/rb21830-1.jpg 2x",
          },
          fluid: {
            base64:
              "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe9mXZUnFAAP/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxEQEv/aAAgBAQABBQInJ6Gx1ctZWWFQIXv/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAbEAACAQUAAAAAAAAAAAAAAAAAERAgITFBYf/aAAgBAQAGPwITiw98MUf/xAAeEAACAgICAwAAAAAAAAAAAAABEQAxIUEgUWFxgf/aAAgBAQABPyHAc1inAQaM7Ae388KM44iqRBUZ9cP/2gAMAwEAAgADAAAAEGDPPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB4QAQEAAgICAwAAAAAAAAAAAAERACExQSBxUYHR/9oACAEBAAE/EDsGLKde8k0CClJglBPkcQltkUA3fodc3LHCw1q83t9axVsK2baLqfnh/9k=",
            aspectRatio: 1,
            src: "/static/3f107f7955942632259607be27147149/f2ca8/rb21830-1.jpg",
            srcSet:
              "/static/fdbc2d7953eb8fca5074bec4ef64e36c/2f7c6/AdobeStock_136086706-scaled.jpg 480w,\\n/static/fdbc2d7953eb8fca5074bec4ef64e36c/63b9c/AdobeStock_136086706-scaled.jpg 960w,\\n/static/fdbc2d7953eb8fca5074bec4ef64e36c/a36fc/AdobeStock_136086706-scaled.jpg 1920w,\\n/static/fdbc2d7953eb8fca5074bec4ef64e36c/d3baa/AdobeStock_136086706-scaled.jpg 2560w",
            sizes: "(max-width: 120px) 100vw, 120px",
          },
        },
      },
    },
  },
}

export const SameBillingAddressCheckbox = ({ deliveryMethod, addressLine1, addressLine2, postcode, city }) => {
 return (
  <Row className="d-none d-lg-block px-3 my-4">
    {deliveryMethod === "homeDelivery" && <Form.Check
        type="checkbox"
        id="billingAddressTheSame"
        label={
          <div>
            <span>Billing address the same as shipping</span>
          </div>
        }
        onClick={() => {
          document.getElementById('addressLine1').value = addressLine1 ? addressLine1 : null
          document.getElementById('addressLine2').value = addressLine2 ? addressLine2 : null
          document.getElementById('postcode').value = postcode ? postcode : null
          document.getElementById('city').value = city ? city : null
        }}
    />}
  </Row>
  )
}

export const RenderCustomerDetails = ({
  deliveryMethod,
  addressLine1,
  addressLine2,
  pickupLocation,
  firstName,
  lastName,
  city,
  postcode,
  email,
}) => {
 return (
    <div className="px-3">
      <h2 className="text-uppercase py-4">1 - delivery options</h2>
      <p className="h3 text-uppercase gray-600">delivery method</p>
      {
        deliveryMethod === 'clickAndCollect'
        ? <p>Free Click & Collect - Central Bikes {pickupLocation} </p>
        : <p>Delivery -
          <span className="d-block">{addressLine1}</span>
          <span className="d-block">{addressLine2}</span>
          <span className="d-block">{city}</span>
          <span className="d-block">{postcode}</span>
        </p>
      }

      <p className="h3 text-uppercase gray-600 pt-4">your information</p>
      <p className="mb-0">Full Name: {firstName} {lastName}</p>
      <p>Email: {email}</p>
      <Link to="/basket" className={`text-dark m-0 p-0`}>Change</Link>
  </div>
 )
}

export const CARD_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      color: "#495057",
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#b6b5be",
      },
    },
    invalid: {
      color: "#f0585e",
      iconColor: "#f0585e",
    },
  },
};



export default product
