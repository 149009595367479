import React from "react"
import * as PropTypes from "prop-types"
import * as styles from "./ProductSelectedAccessories.module.scss"
import ProductSelectedAccessoryCard from "./ProductSelectedAccessoryCard"


const ProductSelectedAccessories = ({ products,queryTermInMonths }) => {

  if(!products || !products.length){
    return <div>Not selected any accessories</div>
  }

  return (
    <div>
      {products.map(({product,variant}) => (
        <div key={product.id} className="mt-3">
          <ProductSelectedAccessoryCard product={product} variant={variant} queryTermInMonths={queryTermInMonths}/>
        </div>
      ))}
    </div>
  )
}

ProductSelectedAccessories.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    variant: PropTypes.shape({
      id: PropTypes.number.isRequired,
    })

  })).isRequired,
  queryTermInMonths: PropTypes.number.isRequired
}

export default ProductSelectedAccessories
