import React, { Component } from "react"
import * as PropTypes from "prop-types"

const CheckoutStep = () => {
    return (
      <div/>
    )
}

CheckoutStep.propTypes = {
  pageLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CheckoutStep
