// extracted by mini-css-extract-plugin
export var assemblyContainer = "IDDFincanceModal-module--assemblyContainer--d8282";
export var bgGrey100 = "IDDFincanceModal-module--bg-grey-100--b247c";
export var bgGrey150 = "IDDFincanceModal-module--bg-grey-150--ab12b";
export var bgGrey200 = "IDDFincanceModal-module--bg-grey-200--742c6";
export var bgGrey300 = "IDDFincanceModal-module--bg-grey-300--c73c8";
export var bgGrey400 = "IDDFincanceModal-module--bg-grey-400--754d8";
export var bgGrey500 = "IDDFincanceModal-module--bg-grey-500--9aa77";
export var bgGrey600 = "IDDFincanceModal-module--bg-grey-600--1bc6b";
export var bgGrey700 = "IDDFincanceModal-module--bg-grey-700--da5dc";
export var bgGrey800 = "IDDFincanceModal-module--bg-grey-800--ce9ee";
export var bgGrey900 = "IDDFincanceModal-module--bg-grey-900--07b7f";
export var marketingBg = "IDDFincanceModal-module--marketingBg--1b79a";
export var offerBtn = "IDDFincanceModal-module--offerBtn--6b54d";
export var priceSticker = "IDDFincanceModal-module--priceSticker--e087f";
export var textGrey100 = "IDDFincanceModal-module--text-grey-100--eebb8";
export var textGrey150 = "IDDFincanceModal-module--text-grey-150--e193b";
export var textGrey200 = "IDDFincanceModal-module--text-grey-200--67a93";
export var textGrey300 = "IDDFincanceModal-module--text-grey-300--7b8f9";
export var textGrey400 = "IDDFincanceModal-module--text-grey-400--e1be7";
export var textGrey500 = "IDDFincanceModal-module--text-grey-500--c9d40";
export var textGrey600 = "IDDFincanceModal-module--text-grey-600--36795";
export var textGrey700 = "IDDFincanceModal-module--text-grey-700--9d9bb";
export var textGrey800 = "IDDFincanceModal-module--text-grey-800--e8e4a";
export var textGrey900 = "IDDFincanceModal-module--text-grey-900--b6898";
export var top = "IDDFincanceModal-module--top--d2d36";